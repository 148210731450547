<template>
  <v-container fluid grid-list-md>
    <v-layout v-if="dataBrowsingAllowed" justify-end>
      <div>
        <transition name="slide-x-transition" mode="out-in">
          <component
            v-bind:is="currentComponent"
            v-if="currentComponent != null"
            ref="filters"
            v-bind="componentProp"
            @filter="searchClicked"
          ></component>
        </transition>
      </div>
      <div>
        <v-layout justify-center fill-height pt-3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                text
                icon
                :disabled="quarantineMode"
                @click="searchClicked"
                v-on="on"
              >
                <fa-icon icon="search" size="lg"></fa-icon>
              </v-btn>
            </template>
            <span>{{ $t("global.search") }}</span>
          </v-tooltip>
          <v-tooltip v-if="chipsMode" bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" text icon @click="resetFilters" v-on="on">
                <fa-icon icon="times" size="lg"></fa-icon>
              </v-btn>
            </template>
            <span>{{ $t("global.cancel") }}</span>
          </v-tooltip>
          <RodoSearchFile
            v-if="!chipsMode"
            @fileSearchReady="onFileSearchReady"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" text icon v-on="on">
                  <fa-icon icon="file-import" size="lg"></fa-icon>
                </v-btn>
              </template>
              <tbody>
                <span>{{ $t("global.importFromCSV.desc") }}</span>
                <table>
                  <tr>
                    <td>{{ $t("global.importFromCSV.name") }}</td>
                    <td>{{ $t("global.importFromCSV.surname") }}</td>
                    <td>{{ $t("global.importFromCSV.phone") }}</td>
                    <td>{{ $t("global.importFromCSV.email") }}</td>
                  </tr>
                </table>

                <span>{{ $t("global.importFromCSV.exampleDesc") }}</span>
                <br />
                <i>{{ $t("global.importFromCSV.example") }}</i>
              </tbody>
            </v-tooltip>
          </RodoSearchFile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" text icon @click="exportToCsv" v-on="on">
                <fa-icon icon="file-export" size="lg"></fa-icon>
              </v-btn>
            </template>
            <span>{{ $t("global.exportToCsv.desc") }}</span>
          </v-tooltip>
          <v-tooltip v-if="!quarantineMode" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                text
                icon
                @click="enableQuarantineMode"
                v-on="on"
              >
                <fa-icon :icon="['fab', 'kickstarter']" size="lg"></fa-icon>
              </v-btn>
            </template>
            <span>{{ $t("global.quarantineSearch") }}</span>
          </v-tooltip>
          <v-tooltip v-if="quarantineMode" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                text
                icon
                @click="disableQuarantineMode"
                v-on="on"
              >
                <fa-icon icon="times" size="lg"></fa-icon>
              </v-btn>
            </template>
            <span>{{ $t("global.cancel") }}</span>
          </v-tooltip>
        </v-layout>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import RodoSearchFilters from "./RodoSearchFilters";
import RodoSearchFile from "./RodoSearchFile";
import RodoSearchChips from "./RodoSearchChips";
import { restrictions } from "../common/RestrictionsCode";
import { EmitError } from "../event-bus";

export default {
  name: "RodoSearch",
  components: {
    RodoSearchFilters,
    RodoSearchChips,
    RodoSearchFile
  },
  data() {
    return {
      currentComponent: RodoSearchFilters,
      componentProp: {},
      quarantineMode: false,
      dataBrowsingAllowed: this.$can("hasRestriction", {
        code: restrictions.dataBrowsing
      })
    };
  },
  computed: {
    chipsMode() {
      return this.currentComponent == RodoSearchChips;
    }
  },
  methods: {
    validateFilters(filters) {
      var isValid = filters.every(el => {
        return el.validate();
      });

      if (!isValid) {
        EmitError(this.$t("rodo.searcherWrongLength"));
        return false;
      }
      return true;
    },
    searchClicked() {
      var data = this.$refs["filters"].getDto();
      if (this.validateFilters(data)) {
        this.$emit("searchClickEvent", data);
      }
    },
    onFileSearchReady(data) {
      this.disableQuarantineMode();
      this.componentProp = { searchElements: data };
      this.currentComponent = RodoSearchChips;
    },
    exportToCsv() {
      this.$emit("exportClickEvent");
    },
    resetFilters() {
      this.currentComponent = RodoSearchFilters;
    },
    disableQuarantineMode() {
      this.quarantineMode = false;
      this.currentComponent = RodoSearchFilters;
    },
    enableQuarantineMode() {
      this.quarantineMode = true;
      this.currentComponent = null;
      this.$emit("quarantineClickEvent");
    }
  }
};
</script>

<style scoped lang="scss">
.layout-height {
  height: 60px;
}
table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
  padding: 5px;
  margin: 10px;
}
</style>
