<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :overlay="false"
    max-width="900px"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text icon color="primary" v-on="{ ...dialog, ...tooltip }">
            <fa-icon icon="address-book" :size="iconSize"></fa-icon>
          </v-btn>
        </template>
        <span>{{ $t("rodo.history") }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline" primary-title>
        <v-layout>{{ $t("rodo.history") }}</v-layout>
        <v-layout justify-end>
          <v-btn icon dark @click="dialog = false">
            <fa-icon icon="times" size="lg"></fa-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-card-text>
        <v-timeline v-show="items[0]" dense>
          <v-timeline-item v-for="(item, index) in items" :key="index" small>
            <v-layout pt-3>
              <v-flex xs3 class="text-truncate">
                <strong>{{ item.operationTime | dateTimeFilter }}</strong>
              </v-flex>
              <v-flex>
                <strong>{{ item.operationUser }}</strong>
                <div class="caption">{{ item.operationDesc }}</div>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
        <v-layout v-show="!loading && !items[0]">
          {{ $t("rodo.noHistory") }}
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import RodoService from "../services/RodoService";
import dateTimeFilters from "../mixins/date-time-filters";
export default {
  name: "ObjectionHistoryModal",
  mixins: [dateTimeFilters],
  props: {
    domain: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    contactFilters: {
      type: Object,
      required: true,
    },
    iconSize: {
      type: String,
      default: "2x",
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      items: [],
    };
  },
  watch: {
    dialog(isOpen) {
      this.getObjectionHistory(isOpen);
    },
  },
  methods: {
    getObjectionHistory(isOpen) {
      if (!isOpen) {
        return;
      }
      this.loading = true;
      RodoService.GetContactHistory(
        this.contactId,
        this.domain,
        this.contactFilters
      ).then((r) => {
        this.items = r || [];
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.v-card__text {
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
