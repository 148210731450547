<template>
  <v-container fluid>
    <RodoSearch
      @searchClickEvent="searchClicked"
      @quarantineClickEvent="quarantineClicked"
      @exportClickEvent="exportClicked"
    ></RodoSearch>
    <RodoTable ref="rodoTable"></RodoTable>
  </v-container>
</template>

<script>
import RodoSearch from "../components/RodoSearch";
import RodoTable from "../components/RodoTable";

export default {
  name: "RodoList",
  components: { RodoSearch, RodoTable },
  methods: {
    searchClicked(searchedData) {
      this.$refs.rodoTable.getData(searchedData);
    },
    exportClicked() {
      this.$refs.rodoTable.exportAllItemToCsv();
    },
    quarantineClicked() {
      this.$refs.rodoTable.getQuarantine();
    }
  }
};
</script>
