<template>
  <div>
    <v-layout row wrap class="pa-3">
      <DisplayLabel
        :label="$t('rodo.name')"
        :value="contact.firstName"
      ></DisplayLabel>
      <DisplayLabel
        :label="$t('rodo.surname')"
        :value="contact.surname"
      ></DisplayLabel>
    </v-layout>
    <v-layout row wrap class="pa-3 odd">
      <DisplayLabel
        :label="$t('rodo.phone')"
        :value="contact.phone"
      ></DisplayLabel>
      <DisplayLabel
        :label="$t('rodo.mail')"
        :value="contact.mail"
      ></DisplayLabel>
    </v-layout>
    <v-layout row wrap class="pa-3">
      <DisplayLabel
        :label="$t('rodo.desc')"
        :value="contact.description"
      ></DisplayLabel>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row wrap class="pa-3">
      <DisplayLabel
        :label="$t('rodo.insertDate')"
        :value="contact.insertTime | dateTimeFilter"
        column="xs3"
      ></DisplayLabel>
      <DisplayLabel
        :label="$t('rodo.system')"
        :value="contact.domainSystemName"
        column="xs3"
      ></DisplayLabel>
      <DisplayLabel
        :label="$t('rodo.expirationDate')"
        :value="contact.expirationDate | dateTimeFilter"
        column="xs3"
      ></DisplayLabel>
      <DisplayLabel
        :label="$t('rodo.module')"
        :value="contact.systemModuleName"
        column="xs3"
      ></DisplayLabel>
    </v-layout>
    <v-divider></v-divider>
  </div>
</template>
<script>
import datetimeFilter from "../mixins/date-time-filters";
import DisplayLabel from "./DisplayLabel.vue";
export default {
  name: "RodoContactDetails",
  components: { DisplayLabel },
  mixins: [datetimeFilter],
  props: {
    contact: Object,
    default: {}
  }
};
</script>
