<template>
  <v-layout v-show="showGrid" column>
    <v-layout row wrap>
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          :loading="loading"
          :headers="headers"
          :options.sync="options"
          :items-per-page-options="rowsPerPageItems"
          :items="items"
          item-key="id"
          show-select
          show-expand
        >
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length + 1">
              <v-layout py-3 px-5 justify-content-center>
                <DisplayLabel
                  :label="$t('rodo.desc')"
                  :value="item.description"
                ></DisplayLabel>
                <DisplayLabel
                  :label="$t('rodo.expirationDate')"
                  :value="item.expirationDate | dateTimeFilter"
                ></DisplayLabel>
              </v-layout>
              <v-divider></v-divider>
            </td>
          </template>
          <template v-slot:item.icons="{ item }">
            <v-tooltip v-if="item.marketingAgreement" bottom>
              <template v-slot:activator="{ on }">
                <fa-icon icon="ban" class="text-primary" v-on="on"></fa-icon>
              </template>
              <span>{{ $t("rodo.marketingObjection") }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.isQuarantine" bottom>
              <template v-slot:activator="{ on }">
                <span class="text-primary font-weight-black" v-on="on">K</span>
              </template>
              <span>{{ $t("rodo.isQuarantine") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.insertTime="{ value }">
            {{ value | dateTimeFilter }}
          </template>
          <template v-slot:item.options="{ item }">
            <v-flex>
              <v-layout>
                <ObjectionHistoryModal
                  :domain="item.domainSystemName"
                  :contact-id="item.foreignKey"
                  :contact-filters="contactFilters(item)"
                  icon-size="lg"
                ></ObjectionHistoryModal>
                <v-flex v-if="dataSaveAllowed">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        color="primary"
                        @click="printContactDetails(item)"
                        v-on="on"
                      >
                        <fa-icon icon="print" size="lg"></fa-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("global.print") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        color="primary"
                        @click="exportContactDetails(item)"
                        v-on="on"
                      >
                        <fa-icon icon="save" size="lg"></fa-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("global.save") }}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-flex>
          </template>
          <v-progress-linear slot="progress" indeterminate></v-progress-linear>
          <template slot="pageText"></template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <ConfirmModal
        :disabled="!isSelected"
        :value="$t('rodo.confirmTextWithCount', { count: selectedCount })"
        @confirm-modal="anonymised"
      >
        <template v-slot:default="{ on }">
          <v-btn
            v-if="dataRemoveAllowed"
            :disabled="!isSelected"
            class="light-button"
            v-on="on"
          >
            {{ $t("rodo.anonymize") }}
          </v-btn>
        </template>
      </ConfirmModal>
      <ConfirmModal
        v-show="!isQuarantineTable"
        :disabled="!isSelected"
        :value="$t('rodo.confirmQuarantine', { count: selectedCount })"
        @confirm-modal="quarantine"
      >
        <template v-slot:default="{ on }">
          <v-btn
            v-if="dataModifyAllowed"
            :disabled="!isSelected"
            class="light-button"
            v-on="on"
          >
            {{ $t("rodo.quarantine") }}
          </v-btn>
        </template>
      </ConfirmModal>
      <ConfirmModal
        v-show="isQuarantineTable"
        :disabled="!isSelected"
        :value="$t('rodo.confirmUnsetQuarantine', { count: selectedCount })"
        @confirm-modal="unsetQuarantine"
      >
        <template v-slot:default="{ on }">
          <v-btn
            v-if="dataModifyAllowed"
            :disabled="!isSelected"
            class="light-button"
            v-on="on"
          >
            {{ $t("rodo.finishQuarantine") }}
          </v-btn>
        </template>
      </ConfirmModal>
    </v-layout>
    <ContactDetails
      ref="ContactDetails"
      :contact="contact"
      style="display:none"
    ></ContactDetails>
  </v-layout>
</template>

<script>
import { Header, ContactFilter } from "../common/Common";
import Printer from "../common/HtmlPrinter";
import dateTimeFilters from "../mixins/date-time-filters";
import { restrictions } from "../common/RestrictionsCode";
import RodoService from "../services/RodoService";
import ContactDetails from "./RodoContactDetails.vue";
import ConfirmModal from "./ConfirmModal.vue";
import DisplayLabel from "./DisplayLabel.vue";
import ObjectionHistoryModal from "./ObjectionHistoryModal.vue";
import { EmitError } from "../event-bus.js";

export default {
  name: "RodoTable",
  components: {
    ContactDetails,
    ConfirmModal,
    DisplayLabel,
    ObjectionHistoryModal
  },
  mixins: [dateTimeFilters],
  data() {
    return {
      loading: false,
      headers: [
        new Header("", "data-table-expand", 48),
        new Header("", "icons", 48),
        new Header(this.$t("rodo.name"), "firstName", 0, true),
        new Header(this.$t("rodo.surname"), "surname", 0, true),
        new Header(this.$t("rodo.phone"), "phone", 0, true),
        new Header(this.$t("rodo.mail"), "mail", 0, true),
        new Header(this.$t("rodo.system"), "domainSystemName", 0, true),
        new Header(this.$t("rodo.module"), "systemModuleName", 0, true),
        new Header(this.$t("rodo.insertDate"), "insertTime", 0, true),
        new Header(this.$t("global.actions"), "options", 200)
      ],
      items: [],
      selected: [],
      options: {
        itemsPerPage: 5
      },
      rowsPerPageItems: [5, 10, 25, 100],
      showGrid: false,
      contact: {},
      isQuarantineTable: false,
      dataSaveAllowed: this.$can("hasRestriction", {
        code: restrictions.dataSave
      }),
      dataRemoveAllowed: this.$can("hasRestriction", {
        code: restrictions.dataDelete
      }),
      dataModifyAllowed: this.$can("hasRestriction", {
        code: restrictions.dataModify
      })
    };
  },
  computed: {
    isSelected() {
      return this.selected[0];
    },
    selectedCount() {
      const selectedIds = this.selected.map(s => s.id);
      return this.items.filter(i => selectedIds.includes(i.id)).length;
    }
  },
  methods: {
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.items.slice();
      }
    },
    getData(filterList) {
      this.isQuarantineTable = false;

      return this._gridData(() => RodoService.Search(filterList))
        .then(data => {
          this.items = data;
        })
        .catch(err => {
          if (err.code === "ECONNABORTED") {
            EmitError(this.$t("global.timeout"));
          } else {
            EmitError(this.$t("global.error"));
          }
        });
    },
    getQuarantine() {
      this.isQuarantineTable = true;
      return this._gridData(RodoService.GetQuarantine).then(data => {
        this.items = data;
      });
    },
    exportAllItemToCsv() {
      if (!this.items[0]) {
        EmitError(this.$t("global.exportToCsv.noData"));
        return false;
      }
      if (this.items.length > 50000) {
        EmitError(this.$t("global.exportToCsv.maxExceeded"));
        return false;
      }
      var arrData = this.items.map(item => ({
        Imię: item.firstName,
        Nazwisko: item.surname,
        Telefon: item.phone,
        "E-mail": item.mail,
        "System Dziedzinowy": item.domainSystemName,
        Moduł: item.systemModuleName,
        "Data wprowadzenia": item.insertTime,
        "Informacje dodatkowe": item.description,
        Kwarantanna: item.isQuarantine ? "Tak" : "Nie"
      }));
      let csvContent = "\uFEFF"; // UTF BOM
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURIComponent(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    printContactDetails(contact) {
      this.contact = contact;
      this.$nextTick(() =>
        Printer.PrintElement(this.$refs["ContactDetails"].$el)
      );
    },
    exportContactDetails(contact) {
      RodoService.ExportContact(contact);
    },
    anonymised() {
      this._gridAction(RodoService.DeleteContacts);
    },
    quarantine() {
      this._gridAction(RodoService.SetQuarantine);
    },
    unsetQuarantine() {
      this._gridAction(RodoService.UnsetQuarantine);
    },
    _gridAction(callback) {
      if (!this.isSelected) {
        return;
      }
      let selected = this.selected.slice();

      callback(selected).then(r => {
        this.items = this.items.filter(
          contact => !selected.some(s => s.id === contact.id)
        );
        this.selected = [];
      });
    },
    _gridData(callback) {
      this.loading = true;
      this.showGrid = true;
      this.selected = [];
      return callback()
        .then(r => {
          return r;
        })
        .finally(() => (this.loading = false));
    },
    contactFilters(contact) {
      return new ContactFilter(
        contact.firstName,
        contact.surname,
        contact.phone,
        contact.mail
      );
    }
  }
};
</script>
<style scoped lang="scss">
svg.expand {
  transition: 0.3s;
  transform: rotateX(180deg);
}
.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.2);
}
</style>
