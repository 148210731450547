<template>
  <v-col :cols="column">
    <div>
      <label class="font-weight-bold">{{ label }}</label>
    </div>
    <div class="mt-2">{{ value }}</div>
  </v-col>
</template>
<script>
export default {
  name: "DisplayLabel",
  props: {
    label: String,
    value: String,
    column: {
      type: String,
      default: "4"
    }
  }
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.mt-2 {
  margin-top: 8px;
}
</style>
