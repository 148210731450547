<template>
  <div>
    <input
      ref="fileInputImport"
      type="file"
      name="fileInputImport"
      accept=".csv"
      @change="selectFile"
    />
    <span @click="openFileExplorer()">
      <slot></slot>
    </span>
  </div>
</template>
<script>
import { EmitError } from "../event-bus.js";
import { ContactFilter } from "../common/Common.js";
import { parse } from "papaparse";

export default {
  name: "FileFilterLoader",
  data() {
    return {
      parserOptions: {
        encoding: "windows-1250",
        skipEmptyLines: true
      }
    };
  },
  methods: {
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    selectFile(e) {
      const file = e.target.files[0];
      if (!file) {
        EmitError(this.$t("global.fileError"));
        return;
      }
      parse(file, {
        ...this.parserOptions,
        complete: (results, file) => {
          if (results.errors.length > 0) {
            EmitError(this.$t("global.fileParseError"));
            return;
          }
          this._mapImportedData(results.data);
        }
      });
    },
    _mapImportedData(data) {
      var importedData = data.map(
        r => new ContactFilter(r[0], r[1], r[2], r[3])
      );
      this.$emit("fileSearchReady", importedData);
    }
  }
};
</script>
<style lang="scss" scoped>
[type="file"] {
  display: none;
}
</style>
