<template>
  <v-item-group multiple>
    <v-subheader>{{
      $tc("global.importFromCSV.appliedFilters", items.length)
    }}</v-subheader>
    <v-chip
      v-for="(item, index) in items"
      :key="index"
      class="ma-1"
      close
      @click:close="removeItem(item)"
      >{{ item.firstname }} {{ item.surname }} {{ item.phone }}
      {{ item.mail }}</v-chip
    >
  </v-item-group>
</template>
<script>
export default {
  name: "RodoSearchChips",
  props: {
    searchElements: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.items = this.searchElements.slice();
  },
  methods: {
    removeItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    getDto() {
      return this.items;
    }
  }
};
</script>
