var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[(_vm.dataBrowsingAllowed)?_c('v-layout',{attrs:{"justify-end":""}},[_c('div',[_c('transition',{attrs:{"name":"slide-x-transition","mode":"out-in"}},[(_vm.currentComponent != null)?_c(_vm.currentComponent,_vm._b({ref:"filters",tag:"component",on:{"filter":_vm.searchClicked}},'component',_vm.componentProp,false)):_vm._e()],1)],1),_c('div',[_c('v-layout',{attrs:{"justify-center":"","fill-height":"","pt-3":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":"","disabled":_vm.quarantineMode},on:{"click":_vm.searchClicked}},on),[_c('fa-icon',{attrs:{"icon":"search","size":"lg"}})],1)]}}],null,false,2642855595)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.search")))])]),(_vm.chipsMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""},on:{"click":_vm.resetFilters}},on),[_c('fa-icon',{attrs:{"icon":"times","size":"lg"}})],1)]}}],null,false,1791869391)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]):_vm._e(),(!_vm.chipsMode)?_c('RodoSearchFile',{on:{"fileSearchReady":_vm.onFileSearchReady}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""}},on),[_c('fa-icon',{attrs:{"icon":"file-import","size":"lg"}})],1)]}}],null,false,1812006492)},[_c('tbody',[_c('span',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.desc")))]),_c('table',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.name")))]),_c('td',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.surname")))]),_c('td',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.phone")))]),_c('td',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.email")))])])]),_c('span',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.exampleDesc")))]),_c('br'),_c('i',[_vm._v(_vm._s(_vm.$t("global.importFromCSV.example")))])])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""},on:{"click":_vm.exportToCsv}},on),[_c('fa-icon',{attrs:{"icon":"file-export","size":"lg"}})],1)]}}],null,false,4220763385)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.exportToCsv.desc")))])]),(!_vm.quarantineMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""},on:{"click":_vm.enableQuarantineMode}},on),[_c('fa-icon',{attrs:{"icon":['fab', 'kickstarter'],"size":"lg"}})],1)]}}],null,false,3697157649)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.quarantineSearch")))])]):_vm._e(),(_vm.quarantineMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":""},on:{"click":_vm.disableQuarantineMode}},on),[_c('fa-icon',{attrs:{"icon":"times","size":"lg"}})],1)]}}],null,false,4273140080)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]):_vm._e()],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }