var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGrid),expression:"showGrid"}],attrs:{"column":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"options":_vm.options,"items-per-page-options":_vm.rowsPerPageItems,"items":_vm.items,"item-key":"id","show-select":"","show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-layout',{attrs:{"py-3":"","px-5":"","justify-content-center":""}},[_c('DisplayLabel',{attrs:{"label":_vm.$t('rodo.desc'),"value":item.description}}),_c('DisplayLabel',{attrs:{"label":_vm.$t('rodo.expirationDate'),"value":_vm._f("dateTimeFilter")(item.expirationDate)}})],1),_c('v-divider')],1)]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(item.marketingAgreement)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('fa-icon',_vm._g({staticClass:"text-primary",attrs:{"icon":"ban"}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("rodo.marketingObjection")))])]):_vm._e(),(item.isQuarantine)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-primary font-weight-black"},on),[_vm._v("K")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("rodo.isQuarantine")))])]):_vm._e()]}},{key:"item.insertTime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFilter")(value))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('ObjectionHistoryModal',{attrs:{"domain":item.domainSystemName,"contact-id":item.foreignKey,"contact-filters":_vm.contactFilters(item),"icon-size":"lg"}}),(_vm.dataSaveAllowed)?_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.printContactDetails(item)}}},on),[_c('fa-icon',{attrs:{"icon":"print","size":"lg"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.print")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.exportContactDetails(item)}}},on),[_c('fa-icon',{attrs:{"icon":"save","size":"lg"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.save")))])])],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":""},slot:"progress"}),_c('template',{slot:"pageText"})],2)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-spacer'),_c('ConfirmModal',{attrs:{"disabled":!_vm.isSelected,"value":_vm.$t('rodo.confirmTextWithCount', { count: _vm.selectedCount })},on:{"confirm-modal":_vm.anonymised},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [(_vm.dataRemoveAllowed)?_c('v-btn',_vm._g({staticClass:"light-button",attrs:{"disabled":!_vm.isSelected}},on),[_vm._v(" "+_vm._s(_vm.$t("rodo.anonymize"))+" ")]):_vm._e()]}}])}),_c('ConfirmModal',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isQuarantineTable),expression:"!isQuarantineTable"}],attrs:{"disabled":!_vm.isSelected,"value":_vm.$t('rodo.confirmQuarantine', { count: _vm.selectedCount })},on:{"confirm-modal":_vm.quarantine},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [(_vm.dataModifyAllowed)?_c('v-btn',_vm._g({staticClass:"light-button",attrs:{"disabled":!_vm.isSelected}},on),[_vm._v(" "+_vm._s(_vm.$t("rodo.quarantine"))+" ")]):_vm._e()]}}])}),_c('ConfirmModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isQuarantineTable),expression:"isQuarantineTable"}],attrs:{"disabled":!_vm.isSelected,"value":_vm.$t('rodo.confirmUnsetQuarantine', { count: _vm.selectedCount })},on:{"confirm-modal":_vm.unsetQuarantine},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [(_vm.dataModifyAllowed)?_c('v-btn',_vm._g({staticClass:"light-button",attrs:{"disabled":!_vm.isSelected}},on),[_vm._v(" "+_vm._s(_vm.$t("rodo.finishQuarantine"))+" ")]):_vm._e()]}}])})],1),_c('ContactDetails',{ref:"ContactDetails",staticStyle:{"display":"none"},attrs:{"contact":_vm.contact}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }